"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const vs = `
  attribute float size;
  void main() {
    gl_PointSize = size;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;
const fs = `
  precision mediump float;
  uniform sampler2D u_texture;
  uniform float u_time;
  void main() {
    vec4 color = texture2D(u_texture, gl_PointCoord);
    if (color.a > 0.1) {
      color.rgb = vec3(1.0);
      color.a -= 0.2;
    } 
    gl_FragColor = color;
  }
`;
class Stars extends THREE.Object3D {
    constructor(width = 1, height = 1, texture) {
        super();
        this.particles = 3000;
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this.balls = [];
        this.uniforms = {
            u_time: { value: 0.0 },
            u_progress: { value: 0.0 },
            u_texture: { value: texture },
        };
        this.vectors = [];
        this.particleSystem = null;
        this.geo = null;
        this._createShape();
        this._createWireframe();
    }
    draw() {
    }
    update() {
        this.uniforms.u_time.value += 0.001;
        if (this.particleSystem) {
            this.particleSystem.rotation.y += 0.0004;
        }
    }
    _createShape() {
        const positions = [];
        const sizes = [];
        const geo = new THREE.BufferGeometry();
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            depthTest: false,
            side: THREE.DoubleSide,
        });
        for (let i = 0; i < this.particles; i++) {
            this.vectors.push(new THREE.Vector3());
            positions.push(Math.random() * 20 - 10, 1 + Math.random() * 5, -Math.random() * 20 + 10);
            sizes.push(Math.random() * 6 + 2);
        }
        geo.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        geo.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1).setUsage(THREE.DynamicDrawUsage));
        this.particleSystem = new THREE.Points(geo, mat);
        this.add(this.particleSystem);
        this.geo = geo;
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.wireframe.visible = false;
        this.add(this.wireframe);
    }
}
exports.default = Stars;
