"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const gsap_1 = require("gsap");
class Header {
    constructor(signals) {
        var _a, _b;
        this.signals = signals;
        this.$header = document.querySelector('#header');
        this.$logo = document.querySelector('#logo');
        this.$btnAbout = document.querySelector('#btnAbout');
        this.$modal = document.querySelector('#modal');
        this.$modalBg = document.querySelector('#modalBg');
        this.$modalCard = document.querySelector('#modalCard');
        this.$btnCloseModal = document.querySelector('#btnCloseModal');
        (_a = this.$btnAbout) === null || _a === void 0 ? void 0 : _a.addEventListener('click', this._openModal.bind(this));
        (_b = this.$btnCloseModal) === null || _b === void 0 ? void 0 : _b.addEventListener('click', this._closeModal.bind(this));
        this.signals.textBlack.add(this._setBlack.bind(this));
        this.signals.textWhite.add(this._setWhite.bind(this));
        this.signals.hideAbout.add(this._hideAbout.bind(this));
        this.signals.showHeader.add(this._show.bind(this));
    }
    _show() {
        gsap_1.gsap.to(this.$header, { display: 'block' });
    }
    _setBlack() {
        var _a, _b;
        (_a = this.$header) === null || _a === void 0 ? void 0 : _a.classList.remove('white');
        (_b = this.$modal) === null || _b === void 0 ? void 0 : _b.classList.remove('white');
    }
    _setWhite() {
        var _a, _b;
        (_a = this.$header) === null || _a === void 0 ? void 0 : _a.classList.add('white');
        (_b = this.$modal) === null || _b === void 0 ? void 0 : _b.classList.add('white');
    }
    _openModal() {
        this.$modal ? (this.$modal.style.display = 'flex') : null;
        gsap_1.gsap.delayedCall(0.1, () => {
            gsap_1.gsap.to(this.$modalBg, { duration: 0.5, opacity: 1 });
            gsap_1.gsap.to(this.$modalCard, {
                delay: 0.4,
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'power2.inOut',
            });
            gsap_1.gsap.delayedCall(0.8, () => {
                var _a;
                (_a = this.$btnCloseModal) === null || _a === void 0 ? void 0 : _a.classList.add('show');
            });
        });
    }
    _closeModal() {
        var _a;
        (_a = this.$btnCloseModal) === null || _a === void 0 ? void 0 : _a.classList.add('fadeOut');
        gsap_1.gsap.to(this.$modalCard, { duration: 0.3, opacity: 0, y: 15 });
        gsap_1.gsap.to(this.$modalBg, {
            delay: 0.2,
            duration: 0.4,
            opacity: 0,
            onComplete: () => {
                var _a, _b;
                this.$modal ? (this.$modal.style.display = 'none') : null;
                (_a = this.$btnCloseModal) === null || _a === void 0 ? void 0 : _a.classList.remove('fadeOut');
                (_b = this.$btnCloseModal) === null || _b === void 0 ? void 0 : _b.classList.remove('show');
            },
        });
    }
    _hideAbout() {
        gsap_1.gsap.to(this.$btnAbout, { display: 'none' });
        gsap_1.gsap.to(this.$modal, { display: 'none' });
    }
}
exports.default = Header;
