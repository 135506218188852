"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
const vs = `
  varying vec2 v_uv;
  uniform float u_time;

  void main() {
    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;
const fs = `
  precision mediump float;
  varying vec2 v_uv;
  uniform float u_radius;
  uniform float u_opacity;
  

  vec2 random2(vec2 st){
    st = vec2( dot(st,vec2(127.1,311.7)), dot(st,vec2(269.5,183.3)) );
    return -1.0 + 2.0*fract(sin(st)*43758.5453123);
  }
              
  // Value Noise by Inigo Quilez - iq/2013
  // https://www.shadertoy.com/view/lsf3WH
  float noise(vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);
    vec2 u = f*f*(3.0-2.0*f);
    return mix( 
      mix( dot( random2(i + vec2(0.0,0.0) ), f - vec2(0.0,0.0) ), dot( random2(i + vec2(1.0,0.0) ), f - vec2(1.0,0.0) ), u.x),
      mix( dot( random2(i + vec2(0.0,1.0) ), f - vec2(0.0,1.0) ), dot( random2(i + vec2(1.0,1.0) ), f - vec2(1.0,1.0) ), u.x), 
      u.y
    );
  }

  void main() {
    vec2 st = 2.0 * v_uv - 1.0;

    vec4 color = vec4(0.0, 0.0, 0.0, 1.0);
    float dist = distance(st, vec2(0.0));
    if (dist > u_radius) {
      float sub = dist - u_radius;
      color.a = sub < 0.01 ? smoothstep(1.0, 0.0, sub/0.01): 0.0; 
    }
    color.a *= u_opacity;
    gl_FragColor = color;
  }
`;
class ApproachCircle extends THREE.Object3D {
    constructor(width = 1, height = 1) {
        super();
        this.uniforms = {
            u_radius: { value: 0.5 },
            u_opacity: { value: 1 },
            u_time: { value: 0.0 },
        };
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this._createShape();
    }
    draw() {
    }
    show(time = 1) {
        this.shape &&
            gsap_1.gsap.to(this.shape.scale, {
                duration: time,
                x: 1,
                y: 1,
                ease: 'power3.inOut',
            });
    }
    hide(time = 1) {
        gsap_1.gsap.to(this.uniforms.u_opacity, { duration: time, value: 0 });
    }
    update() {
        this.uniforms.u_time.value += 0.09;
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 32, 32);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
            wireframe: false,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.shape.scale.set(140, 140, 1);
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = ApproachCircle;
