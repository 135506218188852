"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const vs = `
  varying vec2 v_uv;
  uniform float u_progress;
  uniform float u_seed;
  uniform vec2 u_amplitude;
  uniform vec2 u_frequency;
  uniform float u_closestCorner;

  vec3 permute(vec3 x) { return mod(((x*34.0)+1.0)*x, 289.0); }
  float snoise(vec2 v){
    const vec4 C = vec4(0.211324865405187, 0.366025403784439, -0.577350269189626, 0.024390243902439);
    vec2 i  = floor(v + dot(v, C.yy) );
    vec2 x0 = v -   i + dot(i, C.xx);
    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;
    i = mod(i, 289.0);
    vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 )) + i.x + vec3(0.0, i1.x, 1.0 ));
    vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
    m = m*m ;
    m = m*m ;
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;
    m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
    vec3 g;
    g.x = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
  }

  float getActivation(vec2 uv) {
    return uv.x;
  }

  void main() {
    v_uv = uv;

    vec3 pos = position.xyz;
    float activation = getActivation(uv);
    float latestStart = 0.7;
    float startAt = activation * latestStart;
    float vertexProgress = smoothstep(startAt, 1., u_progress);
    vec3 transformedPos = pos;

    float progressLimit = 0.5;
    float simplexProgress = min(clamp((vertexProgress) / progressLimit, 0., 1.),clamp((1.-vertexProgress) / (1.- progressLimit),0.,1.));
    simplexProgress = smoothstep(0.,1.,simplexProgress);
    float noiseX = snoise(vec2(transformedPos.x + u_seed, transformedPos.y + u_seed + simplexProgress * 1.) * u_frequency.x ) ;
    float noiseY = snoise(vec2(transformedPos.y + u_seed, transformedPos.x + u_seed + simplexProgress * 1.) * u_frequency.y) ;
    transformedPos.x += u_amplitude.x * noiseX * simplexProgress;
    transformedPos.y += u_amplitude.y * noiseY * simplexProgress;
    transformedPos.z += u_amplitude.y * noiseX * simplexProgress;

    pos = transformedPos;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;
const fs = `
  precision mediump float;
  varying vec2 v_uv;
  uniform sampler2D u_texture;

  void main() {
    vec4 color = texture2D(u_texture, v_uv);
    if (color.r < 0.6) {
      color.a *= 0.4;
    }
    gl_FragColor = color;
  }
`;
class FreezeCharacter extends THREE.Object3D {
    constructor(width = 1, height = 1, texture) {
        super();
        this.uniforms = {
            u_progress: { type: 'f', value: 0.0 },
            u_seed: { type: 'f', value: 0.1 },
            u_amplitude: { type: 'v2', value: new THREE.Vector2(0.03, 0.06) },
            u_frequency: { type: 'v2', value: new THREE.Vector2(1, 0.74) },
            u_closestCorner: { type: 'f', value: 0 },
            u_texture: { value: texture },
        };
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this.shadow = null;
        this._createShape(texture);
        this.direction = 0.01;
    }
    draw() {
    }
    update() {
        var _a;
        let progress = this.uniforms.u_progress.value;
        progress += this.direction;
        if (progress > 1) {
            progress = 1;
            this.direction *= -1;
        }
        else if (progress < 0) {
            progress = 0;
            this.direction *= -1;
        }
        this.uniforms.u_progress.value = progress;
        (_a = this.shadow) === null || _a === void 0 ? void 0 : _a.update();
    }
    _createShape(texture) {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 20, 20);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
            wireframe: false,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.shape.position.y = this.height / 2 + 0.2;
        this.add(this.shape);
        this._createShadow(texture);
    }
    _createShadow(texture) {
        this.shadow = new Shadow(this.width, this.height, texture);
        this.shadow.position.set(0, 0, 0.01);
        this.shadow.rotation.x = Math.PI / 2;
        this.add(this.shadow);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.wireframe.position.y = this.height / 2 + 0.2;
        this.add(this.wireframe);
    }
}
exports.default = FreezeCharacter;
const shadowVs = `
  varying vec2 v_uv;
  void main() {
    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;
const shadowFs = `
  precision mediump float;
  varying vec2 v_uv;
  uniform sampler2D u_texture;
  uniform float u_time;
  vec2 sineWave(vec2 uv, vec2 phase) {
    float x = sin( 25.0 * uv.y + 20.0 * uv.x + 12.28 * u_time ) * 0.01;
    //float y = sin( 25.0 * uv.y + 30.0 * uv.x + 6.28 * u_time ) * 0.03;
    return vec2( uv.x + x, uv.y);
  }
  float random (vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
	}
	vec2 wave(vec2 uv) {
		float amplitude = 0.03;
		float frequency = 1.;
		float x = sin(10. * uv.x +  10.* u_time) * amplitude;
		return vec2(uv.x + x, uv.y );
	}

  void main() {

    vec2 uv = sineWave(v_uv, vec2(0.3, 0.1));
    vec4 color = texture2D(u_texture, uv);
    if (color.a > 0.) {
      color.a -= v_uv.y;
    }      
    gl_FragColor = color;
  }
`;
class Shadow extends THREE.Object3D {
    constructor(width = 1, height = 1, texture) {
        super();
        this.uniforms = {
            u_time: { type: 'f', value: 0 },
            u_texture: { value: texture },
        };
        this.width = width;
        this.height = height;
        this.shape = null;
        this.shadow = null;
        this._create();
    }
    update() {
        this.uniforms.u_time.value += 0.01;
    }
    _create() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1);
        const mat = new THREE.ShaderMaterial({
            vertexShader: shadowVs,
            fragmentShader: shadowFs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
        });
        this.shadow = new THREE.Mesh(geo, mat);
        this.add(this.shadow);
    }
}
