"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
class DoorFrame extends THREE.Object3D {
    constructor(width = 1, height = 1) {
        super();
        this.WIDTH = 10;
        this.HEIGHT = 7;
        this.DOOR_WIDTH = 0.2;
        this.DOOR_HEIGHT = 0.4;
        this.TOP_WIDTH = this.WIDTH;
        this.TOP_HEIGHT = (this.HEIGHT - this.DOOR_HEIGHT) / 2;
        this.SIDE_WIDTH = (this.TOP_WIDTH - this.DOOR_WIDTH) / 2;
        this.SIDE_HEIGHT = this.DOOR_HEIGHT;
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this.light = null;
        this.uniforms = {
            u_time: { value: 0.0 },
            u_progress: { value: 0.0 },
        };
        this.door = null;
        this.visible = false;
        this._createShape();
    }
    draw() {
        var _a;
        this.visible = true;
        (_a = this.door) === null || _a === void 0 ? void 0 : _a.open();
    }
    update() {
        var _a;
        this.uniforms.u_time.value += 0.1;
        (_a = this.door) === null || _a === void 0 ? void 0 : _a.update();
    }
    _createShape() {
        this._createFrame();
        this._createDoor();
        this._createLight();
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
    _createDoor() {
        this.door = new Door(this.DOOR_WIDTH, this.DOOR_HEIGHT);
        this.add(this.door);
    }
    _createLight() {
        this.light = new THREE.Mesh(new THREE.BoxBufferGeometry(10, 4, 2), new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide }));
        this.light.position.z = -1.1;
        this.add(this.light);
    }
    _createFrame() {
        const mat = new THREE.MeshBasicMaterial({
            color: 0x000000,
            wireframe: false,
            side: THREE.DoubleSide,
        });
        const top = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.TOP_WIDTH, this.TOP_HEIGHT), mat);
        const bottom = top.clone();
        const left = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.SIDE_WIDTH, this.SIDE_HEIGHT), mat);
        const right = left.clone();
        top.position.set(0, this.TOP_HEIGHT / 2 + this.DOOR_HEIGHT / 2, 0);
        bottom.position.set(0, -this.TOP_HEIGHT / 2 - this.DOOR_HEIGHT / 2, 0);
        left.position.set(-this.SIDE_WIDTH / 2 - this.DOOR_WIDTH / 2, 0, 0);
        right.position.set(this.SIDE_WIDTH / 2 + this.DOOR_WIDTH / 2, 0, 0);
        this.add(top);
        this.add(bottom);
        this.add(left);
        this.add(right);
    }
}
exports.default = DoorFrame;
class Door extends THREE.Object3D {
    constructor(width = 1, height = 1) {
        super();
        this.vs = `
      varying float v_wave;
      uniform float u_time;
      uniform float u_progress;
      #define M_PI 3.1415926535897932384626433832795
      void main() {
        vec3 pos = position;
        float shiftX = -u_progress / 10.;
        pos.x = pos.x + ( pow(sin(1.0 - uv.y), 1.5) * shiftX );
        pos.y -= (1.0-uv.y) * shiftX * 0.8;
        
        pos.z -= (1.0 - uv.y) * shiftX * 0.6;
        pos.z += cos((pos.x + pos.y) * 5.5 + u_time * 3.) * 0.02 * (1.0 - uv.y) * (shiftX * -10.);

        v_wave = pos.z * 10.;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      }
    `;
        this.fs = `
    precision mediump float;
    varying float v_wave;
    uniform float u_doorColor;

    float map(float value, float min1, float max1, float min2, float max2) {
      return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
    }

    void main() {
      gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);

      float wave = v_wave;
			wave = map(wave, -1., 1., 0.0, u_doorColor/255.);
      float shadow = wave;
      gl_FragColor = vec4(shadow, shadow, shadow, 1.);
    }
    `;
        this.width = width;
        this.height = height;
        this.uniforms = {
            u_time: { type: 'f', value: 0.0 },
            u_progress: { type: 'f', value: 0.0 },
            u_doorColor: { type: 'f', value: 0 },
        };
        this.isAnimate = false;
        this.shape = null;
        this._createShape();
    }
    open() {
        gsap_1.gsap.to(this.uniforms.u_progress, {
            duration: 6,
            value: 1,
            ease: 'power2.inOut',
        });
        gsap_1.gsap.to(this.uniforms.u_doorColor, { delay: 3, duration: 3, value: 17 });
        gsap_1.gsap.delayedCall(3, () => {
            this.isAnimate = true;
        });
    }
    update() {
        if (!this.isAnimate)
            return;
        this.uniforms.u_time.value += 0.03;
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 32, 32);
        const mat = new THREE.ShaderMaterial({
            vertexShader: this.vs,
            fragmentShader: this.fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
            wireframe: false,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.add(this.shape);
    }
}
