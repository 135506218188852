"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
const vs = `
  varying vec2 v_uv;
  void main() {
    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;
const fs = `
  varying vec2 v_uv;
  uniform float u_time;
  uniform sampler2D u_texture;
  uniform float u_amp;
  uniform float u_opacity;

  //GLSL Noise Algorithms
  //https://gist.github.com/patriciogonzalezvivo/670c22f3966e662d2f83
  float rand(vec2 n) {
    return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
  }
  float noise(vec2 p){
    vec2 ip = floor(p);
    //ip = p;
    // if (u_toggle) {
    //   ip = p;
    // }
    vec2 u = fract(p);
    u = u * u * (3.0 - 2.0 * u);
    vec2 val1 = ip + vec2(0.0,1.0);
    vec2 val2 = ip + vec2(1.0,1.0);
    float res = mix(
      mix( rand(ip), rand(ip + vec2(1.0,0.0)), u.x ),
      mix( rand(ip+vec2(0.0,1.0)), rand(ip+vec2(1.0,1.0)), u.x )
      ,u.y
    );
    return res*res;
  }

  void main() {
    vec2 uv = v_uv;

    float speed = cos(u_time * 0.05) * 0.2; // default 0.1
    speed = 0.3;
    uv.x += (noise(uv * 10. + u_time * speed) - 0.5) * u_amp;
    uv.y += (noise(uv * 10. + u_time * speed) - 0.5) * u_amp;
    //uv.x += (noise(uv * 1000.0 + u_time * speed) - 0.5) * u_amp;
    //uv.y += (noise(uv * 7. + u_time * 0.2) - 0.5) * u_amp;

    gl_FragColor = texture2D(u_texture, uv);
    gl_FragColor.a *= u_opacity;
  }
`;
class SmallDog extends THREE.Object3D {
    constructor(width = 1, height = 1, texture) {
        super();
        this.uniforms = {
            u_time: { value: 0.0 },
            u_texture: { value: texture },
            u_amp: { value: 0.11 },
            u_opacity: { value: 1.0 },
        };
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this._createShape();
    }
    draw() {
    }
    scaleUp(time) {
        this.shape && gsap_1.gsap.to(this.shape.scale, { duration: time, x: 1, y: 1 });
    }
    scaleUpRight() {
        var _a;
        (_a = this.shape) === null || _a === void 0 ? void 0 : _a.scale.set(1, 1, 1);
    }
    show() {
        gsap_1.gsap.to(this.uniforms.u_amp, {
            duration: 3,
            value: 0,
            ease: 'power2.inOut',
        });
    }
    showRight() {
        this.uniforms.u_amp.value = 0;
    }
    fadeOut() {
        gsap_1.gsap.to(this.uniforms.u_amp, { duration: 1, value: 0.15 });
        gsap_1.gsap.to(this.uniforms.u_opacity, { duration: 1, value: 0.0 });
    }
    update() {
        this.uniforms.u_time.value += 0.05;
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.shape.scale.set(0.2, 0.2, 1);
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = SmallDog;
