"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const typoVs = `
  varying vec2 v_uv;
  void main() {
    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;
const typoFs = `
  precision mediump float;
  varying vec2 v_uv;
  uniform sampler2D u_texture;
  uniform float u_time;

  float random (vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
  }

  vec2 wave(vec2 uv) {
    float amplitude = 0.015;
    float frequency = 1.;
    float x = sin(10. * uv.x +  10.* u_time) * amplitude;
    float y = sin(10. * uv.y +  10.* u_time) * amplitude;
    //x = sin(x) * sin(u_progress) * .3;
    //y = sin(y) * sin(u_progress) * .3;
  
    return vec2(uv.x + x, uv.y + y);
  }

  void main() {
    //vec2 uv = v_uv;
    vec2 uv = wave(v_uv);
    vec4 color = texture2D(u_texture, uv);
    if (color.a == 0.0) {
      color = vec4(10.0/255.0, 10.0/255.0, 10.0/255.0, 1.0);
    }
    gl_FragColor = color;
  }
`;
class WaveTypo extends THREE.Object3D {
    constructor(width = 1, height = 1, texture) {
        super();
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this.uniforms = {
            u_time: { value: 0.0 },
            u_progress: { value: 0.0 },
            u_texture: { value: texture },
        };
    }
    draw() {
        this._createShape();
    }
    update() {
        this.uniforms.u_time.value += 0.01;
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1);
        const mat = new THREE.ShaderMaterial({
            vertexShader: typoVs,
            fragmentShader: typoFs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = WaveTypo;
