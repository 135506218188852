"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
const vs = `
  varying vec2 v_uv;

  #define PI 3.14159265359
  uniform float u_progress;
  uniform float u_time;
  uniform float u_amp;

  void main() {

    vec3 pos = position.xyz;
    float distance = length(uv.xy - 0.5 ); // 중심점과의 거리
    float sizeDist = length(vec2(0.5,0.5)); // 최대반지름
    pos.z += sin(distance * 8. - u_time * 2.)  * u_amp; // 7

    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;
const fs = `
  precision mediump float;
  varying vec2 v_uv;
  uniform float u_progress1;
  uniform vec3 fogColor;
  uniform float fogNear;
  uniform float fogFar;

  void main() {
    vec2 st = v_uv * 40.0;
    st = fract(st);
    float tone = step(0.9, st.x);
    vec4 color = vec4(tone, tone, tone, 1.0);
    if (tone == 0.0) {
      color.a = 0.9;
    }
    if (v_uv.y > u_progress1) {
      color.a = 0.0;
    }
    gl_FragColor = color;
    #ifdef USE_FOG
      #ifdef USE_LOGDEPTHBUF_EXT
        float depth = gl_FragDepthEXT / gl_FragCoord.w;
      #else
        float depth = gl_FragCoord.z / gl_FragCoord.w;
      #endif
      float fogFactor = smoothstep( fogNear, fogFar, depth );
      gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
      //gl_FragColor = vec4(gl_FragCoord.z);
    #endif
  }
`;
class Sea extends THREE.Object3D {
    constructor(width = 1, height = 1) {
        super();
        this.uniforms = {
            u_progress: { value: 0.0 },
            u_progress1: { value: 0.0 },
            u_time: { value: 0.0 },
            u_amp: { value: 0.8 },
            fogColor: { type: 'c', value: new THREE.Color() },
            fogNear: { type: 'f', value: 0.0 },
            fogFar: { type: 'f', value: 0.0 },
        };
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this._rotationZ = 0;
        this._createShape();
        this.rotation.x = -Math.PI / 2;
    }
    draw() {
        gsap_1.gsap.to(this.uniforms.u_progress1, { delay: 1, duration: 6, value: 1 });
    }
    update() {
        this.uniforms.u_time.value += 0.01;
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 20, 20);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
            fog: true,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = Sea;
