"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
class RunWall extends THREE.Object3D {
    constructor(width = 1, height = 1) {
        super();
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this.walls = [];
        this.isAnimate = true;
        this._create();
    }
    draw() {
    }
    update() {
        this.walls.forEach((wall) => {
            wall.position.z += 0.06;
            if (wall.position.z >= 19)
                wall.position.z = -19;
            if (!this.isAnimate)
                return;
            wall.update();
        });
    }
    hide() {
        this.isAnimate = false;
        this.walls.forEach((wall) => {
            wall.hide();
            gsap_1.gsap.to(wall.position, {
                delay: 0.3,
                duration: 1.5,
                x: wall.position.x + (wall.isLeft ? -12 : 12),
                ease: 'power2.inOut',
            });
        });
    }
    setFog(fog) {
        this.walls.forEach((wall) => wall.setFog(fog));
    }
    _create() {
        for (let i = 0; i < 20; i++) {
            const wall = new Wall(10, 20, true);
            wall.position.set(-6, 8, -7 - i * 2);
            this.add(wall);
            this.walls.push(wall);
        }
        for (let i = 0; i < 20; i++) {
            const wall = new Wall(10, 20, false);
            wall.position.set(6, 8, -7 - i * 2);
            this.add(wall);
            this.walls.push(wall);
        }
    }
}
exports.default = RunWall;
const vs = `
  varying vec2 v_uv;
  uniform float u_shiftX;

  #define M_PI 3.1415926535897932384626433832795

  void main() {
    v_uv = uv;

    vec3 pos = position;
    pos.x = pos.x + ((sin(uv.y * M_PI) * u_shiftX * 5.) * .2);

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;
const fs = `
  precision mediump float;
  varying vec2 v_uv;
  uniform float u_progress;
  uniform bool u_direction;
  uniform vec3 fogColor;
  uniform float fogNear;
  uniform float fogFar;

  void main() {
    vec4 color = vec4(0.0, 0.0, 0.0, 1.0);
    if (u_direction == true && v_uv.x > 0.997) {
      color.rgb = vec3(1.0);
      if (v_uv.x < 0.999) {
        float sub = 0.999 - v_uv.x;
        color.rgb = vec3(smoothstep(1.0, 0.0, sub/0.001));
      }
    } else if (u_direction == false && v_uv.x < 0.003) {
      color.rgb = vec3(1.0);
      if (v_uv.x > 0.002) {
        float sub = 0.003 - v_uv.x;
        color.rgb = vec3(smoothstep(0.0, 1.0, sub/0.001));
      }

    }
    gl_FragColor = color;
    #ifdef USE_FOG
      #ifdef USE_LOGDEPTHBUF_EXT
        float depth = gl_FragDepthEXT / gl_FragCoord.w;
      #else
        float depth = gl_FragCoord.z / gl_FragCoord.w;
      #endif
      float fogFactor = smoothstep( fogNear, fogFar, depth );
      gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
      //gl_FragColor = vec4(gl_FragCoord.z);
    #endif
  }
`;
class Wall extends THREE.Object3D {
    constructor(width = 1, height = 1, isLeft = true) {
        super();
        this.uniforms = {
            u_direction: { value: isLeft },
            u_progress: { value: 0.0 },
            u_shiftX: { value: 0.4 },
            fogColor: { type: 'c', value: new THREE.Color() },
            fogNear: { type: 'f', value: 0.0 },
            fogFar: { type: 'f', value: 0.0 },
        };
        this.isLeft = isLeft;
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this._createShape();
        this.time = 0;
    }
    draw() {
    }
    update() {
        this.time += 0.01;
        this.uniforms.u_shiftX.value = Math.sin(this.time) * 1.8;
    }
    hide() {
        gsap_1.gsap.to(this.uniforms.u_shiftX, {
            duration: 1,
            value: this.isLeft ? -2 : 2,
            ease: 'power2.inOut',
        });
    }
    setFog(fog) {
        this.uniforms.fogColor = { type: 'c', value: fog.color };
        this.uniforms.fogNear = { type: 'f', value: fog.near };
        this.uniforms.fogFar = { type: 'f', value: fog.far };
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 20, 20);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            side: THREE.DoubleSide,
            fog: true,
            wireframe: false,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.add(this.shape);
    }
}
