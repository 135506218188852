"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
const vs = `
  varying vec2 v_uv;
  void main() {
    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }`;
const fs = `
  precision mediump float;
  varying vec2 v_uv;
  uniform float u_progress;
  uniform float u_opacity;
  uniform sampler2D u_texture0;
  uniform sampler2D u_texture1;

  void main() {
    vec4 color0 = texture2D(u_texture0, v_uv);
    vec4 color1 = texture2D(u_texture1, v_uv);
    vec4 color = mix(color0, color1, u_progress);
    color.a *= u_opacity;
    gl_FragColor = color;
  }
`;
class Sprite extends THREE.Object3D {
    constructor(opt) {
        super();
        this.width = opt.width || 1;
        this.height = opt.height || 1;
        this.images = opt.images || [];
        this.length = this.images.length;
        this.term = opt.term * 60 || 60;
        this.uniforms = {
            u_progress: { value: 0.0 },
            u_opacity: { value: 0.0 },
            u_texture0: { value: this.images[0] },
            u_texture1: { value: this.images[1] },
        };
        this.timer = 0;
        this.isShow = false;
        this.idx = 0;
        this.shape = null;
        this.wireframe = null;
        this._createShape();
    }
    draw() {
    }
    update() {
        if (this.images.length <= 1)
            return;
        this.timer += 1;
        if (this.timer > this.term) {
            this.timer = 0;
            this.changeSprite();
        }
    }
    changeSprite() {
        const curr = this.images[this.idx];
        this.idx += 1;
        if (this.idx >= this.length)
            this.idx = 0;
        const next = this.images[this.idx];
        this.uniforms.u_texture0.value = curr;
        this.uniforms.u_texture1.value = next;
        this.uniforms.u_progress.value = 0;
        gsap_1.gsap.to(this.uniforms.u_progress, {
            duration: 0.05,
            value: 1,
            ease: 'none',
        });
    }
    show(time = 1) {
        gsap_1.gsap.to(this.uniforms.u_opacity, {
            duration: time,
            value: 1,
            onComplete: () => {
                this.isShow = true;
            },
        });
    }
    hide(time = 1) {
        this.isShow = false;
        gsap_1.gsap.to(this.uniforms.u_opacity, { duration: time, value: 0 });
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 32, 32);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = Sprite;
