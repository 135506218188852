"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
const vs = `
  varying vec2 v_uv;

  #define PI 3.14159265359

  uniform float u_time;
  uniform float u_amp;
  uniform float u_freq;

  void main() {
    vec3 pos = position.xyz;
    float distance = length(uv.xy - 0.5 ); // 중심점과의 거리
    float sizeDist = length(vec2(0.5,0.5)); // 최대반지름
    pos.z += sin(distance * u_freq - u_time)  * u_amp; // 7
    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;
const fs = `
  precision mediump float;
  varying vec2 v_uv;
  uniform float u_step;
  uniform vec3 fogColor;
  uniform float fogNear;
  uniform float fogFar;

  void main() {
    vec2 st = v_uv * u_step;
    st = fract(st);
    float tone = step(0.5, st.x);
    vec4 color = vec4(tone);
    if (color.a == 0.0) {
      color = vec4(0.0, 0.0, 0.0, 1.);
    }
    if (u_step == 0.0) {
      color = vec4(1.0);
    }
    gl_FragColor = color;

    #ifdef USE_FOG
      #ifdef USE_LOGDEPTHBUF_EXT
        float depth = gl_FragDepthEXT / gl_FragCoord.w;
      #else
        float depth = gl_FragCoord.z / gl_FragCoord.w;
      #endif
      float fogFactor = smoothstep( fogNear, fogFar, depth );
      gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
      //gl_FragColor = vec4(gl_FragCoord.z);
    #endif
  }
`;
class SplashCircle extends THREE.Object3D {
    constructor(width = 1, height = 1) {
        super();
        this.uniforms = {
            u_time: { value: 2.0 },
            u_freq: { value: 0.0 },
            u_amp: { value: 0 },
            u_step: { value: 0 },
            fogColor: { type: 'c', value: new THREE.Color() },
            fogNear: { type: 'f', value: 0.0 },
            fogFar: { type: 'f', value: 0.0 },
        };
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this._createShape();
        this.timeGap = Math.random() * 0.1 + 0.02;
        this.amp = Math.random() * 0.2 + 0.1;
        this.freq = Math.random() * 2 + 3;
        this.stepGap = Math.random() * 0.03 + 0.01;
        this.maxStep = Math.random() * 5 + 2;
        this.is = {
            step: false,
            flap: false,
        };
    }
    setFog(fog) {
        this.uniforms.fogColor = { type: 'c', value: fog.color };
        this.uniforms.fogNear = { type: 'f', value: fog.near };
        this.uniforms.fogFar = { type: 'f', value: fog.far };
    }
    drawStep() {
        this.uniforms.u_step.value = 0;
        this.is.step = true;
    }
    drawFlap() {
        this.is.flap = true;
        gsap_1.gsap.to(this.uniforms.u_amp, {
            duration: 0.4,
            value: this.amp,
            ease: 'power1.out',
        });
        gsap_1.gsap.to(this.uniforms.u_freq, {
            duration: 0.4,
            value: this.freq,
            ease: 'power1.out',
        });
    }
    update() {
        if (this.is.step) {
            this.uniforms.u_step.value += this.stepGap;
            if (this.uniforms.u_step.value > this.maxStep) {
                this.uniforms.u_step.value = this.maxStep;
                this.stepGap *= -1;
            }
            else if (this.uniforms.u_step.value < 0.1) {
                this.uniforms.u_step.value = 0.1;
                this.stepGap *= -1;
            }
        }
        this.uniforms.u_time.value += this.timeGap;
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 16, 16);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
            wireframe: false,
            fog: true,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.shape.rotation.x = -Math.PI / 2;
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = SplashCircle;
