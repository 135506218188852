"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
const vs = `
  varying vec2 v_uv;
  void main() {
    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }`;
const fs = `
  precision mediump float;
  varying vec2 v_uv;
  uniform float u_opacity;
  uniform float u_frame;
  uniform float u_length;
  uniform sampler2D u_texture;

  void main() {
    vec2 uv = v_uv;
    uv.x = uv.x / u_length + u_frame;
    vec4 color = texture2D(u_texture, uv);
    color.a *= u_opacity;
    gl_FragColor = color;
  }
`;
class SpriteAnimation extends THREE.Object3D {
    constructor(opt) {
        super();
        this.width = opt.width || 1;
        this.height = opt.height || 1;
        this.texture = opt.texture;
        this.length = opt.length;
        this.term = opt.term * 60 || 60;
        this.once = opt.once;
        this.stop = false;
        this.uniforms = {
            u_opacity: { value: 0.0 },
            u_texture: { value: this.texture },
            u_length: { value: this.length },
            u_frame: { value: 0.0 },
        };
        this.timer = 0;
        this.idx = 0;
        this.shape = null;
        this.wireframe = null;
        this._createShape();
    }
    draw() {
    }
    update() {
        if (this.stop)
            return;
        this.timer += 1;
        if (this.timer > this.term) {
            this.timer = 0;
            this.changeSprite();
        }
    }
    changeSprite() {
        this.idx += 1;
        if (this.idx >= this.length && this.once) {
            this.stop = true;
            return;
        }
        if (this.idx >= this.length)
            this.idx = 0;
        this.uniforms.u_frame.value = this.idx / this.length;
    }
    show(time = 1) {
        gsap_1.gsap.to(this.uniforms.u_opacity, {
            duration: time,
            value: 1,
            ease: 'none',
        });
    }
    hide(time = 1) {
        gsap_1.gsap.to(this.uniforms.u_opacity, { duration: time, value: 0 });
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 32, 32);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = SpriteAnimation;
