"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
const vs = `
  uniform float u_progress;

  void main() {
    
    float maxDistance = distance(vec2(0.), vec2(0.8));
    float dist = distance(vec2(0.), uv-0.5);
    float activation = smoothstep(0., maxDistance, dist);
    float startAt = activation * 1.; // 시작 시간은 uv 위치에 따라 달라지게 구현 ( u_progress에서 타이밍을 다르게 조절 )
    float vertexProgress = smoothstep(startAt, 1., u_progress); // 위치값의 progress
    vec2 scale = vec2(0. + 15. * vertexProgress);
    vec3 pos = position;
    //pos.xy *= scale;
    pos.z += scale.x;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;
const fs = `
  precision mediump float;
  uniform vec3 fogColor;
  uniform float fogNear;
  uniform float fogFar;

  void main() {
    gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
    #ifdef USE_FOG
      #ifdef USE_LOGDEPTHBUF_EXT
        float depth = gl_FragDepthEXT / gl_FragCoord.w;
      #else
        float depth = gl_FragCoord.z / gl_FragCoord.w;
      #endif
      float fogFactor = smoothstep( fogNear, fogFar, depth );
      gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
      //gl_FragColor = vec4(gl_FragCoord.z);
    #endif
  }
`;
class Floor extends THREE.Object3D {
    constructor(width = 1, height = 1) {
        super();
        this.uniforms = {
            u_progress: { value: 0.0 },
            fogColor: { type: 'c', value: new THREE.Color() },
            fogNear: { type: 'f', value: 0.0 },
            fogFar: { type: 'f', value: 0.0 },
        };
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this._createShape();
    }
    rise(duration) {
        gsap_1.gsap.to(this.uniforms.u_progress, {
            duration: duration,
            value: 1,
            ease: 'expo.out',
        });
    }
    update() {
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 32, 32);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
            fog: true,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.shape.rotation.x = -Math.PI / 2;
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = Floor;
