"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
class Fullmoon extends THREE.Object3D {
    constructor() {
        super();
        this.vs = `
      varying vec2 v_uv;
      void main() {
        v_uv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `;
        this.fs = `
      precision mediump float;
      varying vec2 v_uv;
      uniform vec2 u_smallCenter;
      uniform float u_smallRadius;
    
      void main() {
        float radius = 0.9;
        vec2 st = 2.0 * v_uv - 1.0;
        float dist = distance(st, vec2(0.0));
        vec4 color = vec4(1.0, 1.0, 1.0, 1.0);
        if (dist < radius) {
          float smallDist = distance(st, u_smallCenter);
          if (smallDist < u_smallRadius) {
            //color = vec4(1.0, 0.0, 0.0, 1.0);
            color.a = 0.0;
          } else {
            float sub = radius - dist;
            color.a = sub < 0.1 ? smoothstep(0.0, 1.0, sub/0.1): 1.0; 
          }
        } else {
          color.a = 0.0;
        }
        //color = vec4(1.0);
        gl_FragColor = color;
      }
    `;
        this.uniforms = {
            u_progress: { value: 0.0 },
            u_smallCenter: { value: new THREE.Vector3(0.0, 0.0) },
            u_smallRadius: { value: 2 },
        };
        this.width = 0.5;
        this.height = 0.5;
        this.shape = null;
        this.gradient = null;
        this._createShape();
        this._createGradient();
    }
    animate() {
        var _a;
        gsap_1.gsap.to(this.uniforms.u_smallRadius, {
            duration: 5,
            value: 0.0,
            ease: 'none',
        });
        gsap_1.gsap.to(this.uniforms.u_smallCenter.value, {
            duration: 5,
            x: 1.4,
            ease: 'none',
        });
        (_a = this.gradient) === null || _a === void 0 ? void 0 : _a.shine();
    }
    fadeOut() {
        var _a;
        gsap_1.gsap.to(this.uniforms.u_smallRadius, {
            duration: 1,
            value: 2,
            ease: 'none',
        });
        gsap_1.gsap.to(this.uniforms.u_smallCenter.value, {
            duration: 1,
            x: 0,
            ease: 'none',
        });
        (_a = this.gradient) === null || _a === void 0 ? void 0 : _a.fadeOut();
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1);
        const mat = new THREE.ShaderMaterial({
            vertexShader: this.vs,
            fragmentShader: this.fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.add(this.shape);
    }
    _createGradient() {
        this.gradient = new Gradient(3, 3);
        this.gradient.position.set(0, 0.04, 0.1);
        this.add(this.gradient);
    }
}
exports.default = Fullmoon;
class Gradient extends THREE.Object3D {
    constructor(width = 1, height = 1) {
        super();
        this.vs = `
      varying vec2 v_uv;
      void main() {
        v_uv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `;
        this.fs = `
      precision mediump float;
      varying vec2 v_uv;
      uniform float u_progress;

      void main() {
        vec2 st = 2.0 * v_uv - 1.0;
        vec4 color = vec4(1.0, 1.0, 1.0, 1.0);
        float dist = distance(st, vec2(0.0));
        float opacity = smoothstep(u_progress, 0.0, distance(st, vec2(0.0))); // 그라데이션 원
        opacity = opacity / 3.;
        color.a = opacity;
        gl_FragColor = color;
      }
    `;
        this.width = width;
        this.height = height;
        this.uniforms = {
            u_progress: { value: 0.0 },
        };
        this.shape = null;
        this._createShape();
    }
    shine() {
        gsap_1.gsap.to(this.uniforms.u_progress, { delay: 3, duration: 4, value: 1 });
    }
    fadeOut() {
        gsap_1.gsap.to(this.uniforms.u_progress, { duration: 0.5, value: 0 });
    }
    _createShape() {
        this.shape = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.ShaderMaterial({
            vertexShader: this.vs,
            fragmentShader: this.fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
        }));
        this.add(this.shape);
    }
}
