"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const vs = `
  varying float v_wave;
  uniform float u_time;
  uniform float u_frequency;
  uniform float u_amplitude;

  void main() {
    vec3 pos = position;
    pos.z += sin((pos.x + pos.y) * u_frequency + 8. * u_time) * u_amplitude;
    v_wave = -pos.z * 20.;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;
const fs = `
  precision mediump float;
  varying float v_wave;
  uniform vec3 fogColor;
  uniform float fogNear;
  uniform float fogFar;

  float map(float value, float min1, float max1, float min2, float max2) {
    return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
  }

  void main() {
    float wave = map(v_wave, -1.0, 1.0, 0.0, 0.2);
    float shadow = 1.0 - wave;
    vec3 color = vec3(1.0, 1.0, 1.0) * shadow;
    gl_FragColor = vec4(color, 1.);

    #ifdef USE_FOG
      #ifdef USE_LOGDEPTHBUF_EXT
        float depth = gl_FragDepthEXT / gl_FragCoord.w;
      #else
        float depth = gl_FragCoord.z / gl_FragCoord.w;
      #endif
      float fogFactor = smoothstep( fogNear, fogFar, depth );
      gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
      //gl_FragColor = vec4(gl_FragCoord.z);
    #endif
  }
`;
class Paper extends THREE.Object3D {
    constructor(width = 1, height = 1) {
        super();
        this.uniforms = {
            u_progress: { value: 0.0 },
            u_time: { value: Math.random() * 2.0 },
            u_frequency: { value: Math.random() * 2 + 4 },
            u_amplitude: { value: Math.random() * 0.07 + 0.03 },
            fogColor: { type: 'c', value: new THREE.Color() },
            fogNear: { type: 'f', value: 0.0 },
            fogFar: { type: 'f', value: 0.0 },
        };
        this.gap = Math.random() * 0.03 + 0.01;
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this._createShape();
    }
    draw() {
    }
    update() {
        this.uniforms.u_time.value += this.gap;
    }
    setUniforms(key, value) {
        this.uniforms[key].value = value;
    }
    setGap(value) {
        this.gap = value;
    }
    setFog(fog) {
        this.uniforms.fogColor = { type: 'c', value: fog.color };
        this.uniforms.fogNear = { type: 'f', value: fog.near };
        this.uniforms.fogFar = { type: 'f', value: fog.far };
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 10, 10);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
            wireframe: false,
            fog: true,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.add(this.shape);
    }
}
exports.default = Paper;
