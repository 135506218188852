"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const gsap_1 = require("gsap");
const vs = `
  varying vec2 v_uv;
  

  mat4 rotation3d(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    return mat4(
      oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
      oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
      oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
      0.0,                                0.0,                                0.0,                                1.0
    );
  }
  vec3 rotate(vec3 v, vec3 axis, float angle) {
    return (rotation3d(axis, angle) * vec4(v, 1.0)).xyz;
  }

  

  void main() {
    v_uv = uv;
    vec3 pos = position;

    // float amplitude = sin(u_progress) * 0.03 + 0.01;
		// float x = u_progress + (u_progress + uv.x * u_progress);
    // pos.x   += sin(14. * uv.x  + 8. * u_progress) * amplitude;
    
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;
const fs = `
  //precision mediump float;
  varying vec2 v_uv;

  uniform float u_time;
  uniform float u_progress;
  uniform float u_opacity;
  uniform sampler2D u_texture;
  uniform bool u_toggle;
  uniform float u_range;
  uniform float u_amp;

  //GLSL Noise Algorithms
  //https://gist.github.com/patriciogonzalezvivo/670c22f3966e662d2f83
  float rand(vec2 n) {
    return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
  }
  float noise(vec2 p){
    vec2 ip = floor(p);
    //ip = p;

    vec2 u = fract(p);
    u = u * u * (3.0 - 2.0 * u);

    vec2 val1 = ip + vec2(0.0,1.0);
    vec2 val2 = ip + vec2(1.0,1.0);

    float res = mix(
      mix( rand(ip), rand(ip + vec2(1.0,0.0)), u.x ),
      mix( rand(ip+vec2(0.0,1.0)), rand(ip+vec2(1.0,1.0)), u.x )
      ,u.y
    );
    return res*res;
  }

  float map(float value, float min1, float max1, float min2, float max2) {
    return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
  }

  void main() {
    vec2 uv = v_uv;
    float speed = cos(u_time * 0.05) * 0.1; // default 0.1
    speed = 0.1;
    uv.x += (noise(uv * u_range + u_time * speed) - 0.25) * u_amp;
    uv.y += (noise(uv * u_range + u_time * speed) - 0.25) * u_amp;
    vec4 color = texture2D(u_texture, uv);
    color.a = map(color.a, 0.0, 1.0, 0.0, u_opacity);

    gl_FragColor = color;
  }
`;
class IntroText extends THREE.Object3D {
    constructor(width = 1, height = 1, texture) {
        super();
        this.uniforms = {
            u_time: { value: 0.0 },
            u_progress: { value: 0.0 },
            u_texture: { value: texture },
            u_opacity: { value: 0.0 },
            u_amp: { value: 0.0 },
            u_range: { value: 300 },
        };
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this.bg = null;
        this._createShape();
    }
    fadeIn(duration, cb) {
        gsap_1.gsap.to(this.uniforms.u_opacity, {
            duration: duration,
            value: 0.9,
            ease: 'sine.inOut',
        });
        gsap_1.gsap.to(this.uniforms.u_amp, { delay: 1.5, duration: 3, value: 0.01 });
        gsap_1.gsap.delayedCall(duration + 1.5, () => {
            cb();
        });
    }
    fadeOut(duration, cb) {
        gsap_1.gsap.to(this.uniforms.u_amp, { duration: 2, value: 0.3 });
        gsap_1.gsap.to(this.uniforms.u_range, { duration: 0.1, value: 400 });
        gsap_1.gsap.to(this.uniforms.u_opacity, {
            delay: 1,
            duration: duration,
            value: 0.0,
            onComplete: () => {
                cb();
            },
        });
    }
    update() {
        this.uniforms.u_time.value += 0.1;
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = IntroText;
