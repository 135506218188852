"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const gsap_1 = require("gsap");
const THREE = __importStar(require("three"));
const vs = `
  varying vec2 v_uv;
  uniform float u_progress;
  void main() {
    v_uv = uv;

    float amplitude = sin(u_progress) * 0.01 + 0.001;
    float x = u_progress + (u_progress + uv.x * u_progress);
    vec3 pos = position;
    pos.z += sin(14. * uv.x  + 8. * u_progress) * amplitude;

    // float activation = uv.x;
    // float latestStart = 0.3; // 효과 조절
    // float startAt = activation * latestStart;
    // float vertexProgress = smoothstep(startAt, 1., u_progress);
    // vec2 scale = vec2( vertexProgress);
        
    // vec3 pos = position;
    // pos.z = scale.x;
			
        
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;
const fs = `
  varying vec2 v_uv;
  uniform sampler2D u_texture;
  uniform float u_time;
  uniform bool u_toggle;

  //GLSL Noise Algorithms
  //https://gist.github.com/patriciogonzalezvivo/670c22f3966e662d2f83
  float rand(vec2 n) {
    return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
  }
  float noise(vec2 p){
    vec2 ip = floor(p);
    if (u_toggle) {
      ip = p;
    }
    vec2 u = fract(p);
    u = u * u * (3.0 - 2.0 * u);
    vec2 val1 = ip + vec2(0.0,1.0);
    vec2 val2 = ip + vec2(1.0,1.0);
    float res = mix(
      mix( rand(ip), rand(ip + vec2(1.0,0.0)), u.x ),
      mix( rand(ip+vec2(0.0,1.0)), rand(ip+vec2(1.0,1.0)), u.x )
      ,u.y
    );
    return res*res;
  }

  void main() {
    vec2 uv = v_uv;
    float speed = cos(u_time * 0.05) * 0.2; // default 0.1
    speed = 0.01;
    uv.x += (noise(uv * 10.0 + u_time * speed) - 0.5) * 0.05;
    uv.y += (noise(uv * 7. + u_time * 0.2) - 0.5) * 0.05;
    gl_FragColor = texture2D(u_texture, uv);
  }
`;
class Hole extends THREE.Object3D {
    constructor(width = 1, height = 1, texture) {
        super();
        this.width = width;
        this.height = height;
        this.shape = null;
        this.wireframe = null;
        this.uniforms = {
            u_time: { value: 0.0 },
            u_progress: { value: 0.0 },
            u_toggle: { type: 'bool', value: true },
            u_texture: { value: texture },
        };
        this.toggleTimer = 0;
        this.isWave = false;
        this._createShape();
    }
    draw(cb) {
        if (!this.shape)
            return;
        gsap_1.gsap.to(this.shape.position, { duration: 1, y: 0, ease: 'power2.inOut' });
        gsap_1.gsap.to(this.shape.scale, {
            delay: 0.1,
            duration: 3,
            x: 1,
            y: 1,
            ease: 'power3.inOut',
        });
        gsap_1.gsap.delayedCall(2, () => {
            this.isWave = true;
            cb();
        });
        gsap_1.gsap.to(this.shape.position, {
            delay: 1.7,
            duration: 2,
            y: 0.3,
            ease: 'sine.inOut',
        });
    }
    setFog() {
    }
    hide(cb) {
        if (!this.shape)
            return;
        gsap_1.gsap.to(this.shape.rotation, {
            delay: 0.5,
            duration: 3,
            x: 0,
            ease: 'sine.inOut',
        });
        gsap_1.gsap.to(this.shape.scale, { duration: 2, x: 4, y: 4, ease: 'sine.inOut' });
        gsap_1.gsap.to(this.shape.position, {
            delay: 0.5,
            duration: 2,
            y: 0.8,
            ease: 'sine.inOut',
        });
        gsap_1.gsap.delayedCall(2.1, () => {
            cb();
        });
    }
    update() {
        this.uniforms.u_time.value += 0.1;
        if (!this.isWave)
            return;
        this.uniforms.u_progress.value += 0.003;
    }
    _createShape() {
        const geo = new THREE.PlaneBufferGeometry(this.width, this.height, 32, 32);
        const mat = new THREE.ShaderMaterial({
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: this.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
            wireframe: false,
        });
        this.shape = new THREE.Mesh(geo, mat);
        this.shape.scale.set(0.01, 0.01, 1);
        this.shape.rotation.x = -Math.PI / 2;
        this.shape.position.y = -0.5;
        this.add(this.shape);
    }
    _createWireframe() {
        this.wireframe = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.width, this.height, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }));
        this.add(this.wireframe);
    }
}
exports.default = Hole;
